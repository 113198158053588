// App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Violations from './Violations';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/violations" />} />
      <Route path="/violations" element={<Violations />} />
    </Routes>
  );
}

export default App;
