/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "violationssubscribe",
            "endpoint": "https://bmiue8k7zi.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:31a398a4-1345-4074-a9ff-2752b8b76bd5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kWUeIPRHO",
    "aws_user_pools_web_client_id": "3pni5en2ai044uhjm2frihpr0m",
    "oauth": {
        "domain": "citybuilder-main.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://main.d221v9l1l4ci2m.amplifyapp.com/violations/",
        "redirectSignOut": "https://main.d221v9l1l4ci2m.amplifyapp.com/violations/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
