// App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useNavigate, useLocation } from 'react-router-dom';
import Intercom from '@intercom/messenger-js-sdk';
import { initGA, logPageView } from './analytics';
import './styles.css'

Amplify.configure(awsconfig);

function Violations() {
  const [input, setInput] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [bin, setBin] = useState('');
  const [property, setProperty] = useState(' ');
  const [address, setAddress] = useState('');
  const [borough, setBorough] = useState('');
  const [dobViolations, setDobViolations] = useState([]);
  const [ecbViolations, setEcbViolations] = useState([]);
  const [hpdViolations, setHpdViolations] = useState([]);
  const [landmarkViolations, setLandmarkViolations] = useState([]);
  const [fdnyViolations, setFdnyViolations] = useState([]);
  const [email, setEmail] = useState('');
  const [showActiveOnly, setShowActiveOnly] = useState(true); // State for the toggle
  const [selectedTab, setSelectedTab] = useState('DOB'); // State for the selected tab

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
    document.title = "NYC Violations Tracking";
    const params = new URLSearchParams(location.search);
    const addressParam = params.get('address');
    const violationsParam = params.get('violations');
    if (addressParam) {
      setInput(addressParam);
      if (violationsParam) {
        setSelectedTab(violationsParam);
      }
      fetchBinOrAddress(addressParam);
    }
  }, [location.search, showActiveOnly]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (input) {
      setShowResults(false);
      navigate(`/violations?address=${encodeURIComponent(input)}&violations=${selectedTab}`);
      fetchBinOrAddress(input);
    }
  };

  const fetchBinOrAddress = async (input) => {
    const property = await fetchProperty(input);
    if (property && property.features && property.features.length > 0) {
      setProperty(property);
      const binData = property.features[0].properties.addendum.pad.bin;
      if (binData) {
        setBin(binData);
        fetchViolations(binData, property);
        setShowResults(true);
      }
    } else {
      setProperty(null);
      setShowResults(false);
    }
  };

  const fetchProperty = async (address) => {
    try {
      const response = await axios.get(`https://geosearch.planninglabs.nyc/v2/autocomplete`, {
        params: {
          text: address
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching BIN:', error);
      return null;
    }
  };

  const fetchViolations = async (bin, property) => {
    fetchDobViolations(bin);
    fetchEcbViolations(bin);
    fetchHpdViolations(bin);
    fetchLandmarkViolations(bin);
    fetchFdnyViolations(property); // Pass the address to fetch FDNY violations
  };

  const fetchDobViolations = async (bin) => {
    try {
      const response = await axios.get(`https://data.cityofnewyork.us/resource/3h2n-5cm9.json`, {
        params: {
          bin: bin
        }
      });

      let violations = response.data;
      if (showActiveOnly) {
        violations = violations.filter(violation => (violation.violation_category || "").includes("ACTIVE"));
      }
      const sortedViolations = violations.sort((a, b) => {
        const dateA = parseISO(a.disposition_date || a.issue_date);
        const dateB = parseISO(b.disposition_date || b.issue_date);
        return dateB - dateA; // Sort by date descending
      });

      setDobViolations(sortedViolations);
    } catch (error) {
      console.error('Error fetching DOB violations:', error);
    }
  };

  const fetchEcbViolations = async (bin) => {
    try {
      const response = await axios.get(`https://data.cityofnewyork.us/resource/6bgk-3dad.json`, {
        params: {
          bin: bin
        }
      });

      let violations = response.data;
      if (showActiveOnly) {
        violations = violations.filter(violation => !((violation.ecb_violation_status || "").includes("RESOLVE")) && !(violation.hearing_status || "").includes("WRITTEN OFF"));
      }
      const sortedViolations = violations.sort((a, b) => {
        const dateA = parseISO(a.hearing_date || a.served_date || a.issue_date);
        const dateB = parseISO(b.hearing_date || b.served_date || b.issue_date);
        return dateB - dateA; // Sort by date descending
      });

      setEcbViolations(sortedViolations);
    } catch (error) {
      console.error('Error fetching ECB violations:', error);
    }
  };

  const fetchHpdViolations = async (bin) => {
    try {
      const response = await axios.get(`https://data.cityofnewyork.us/resource/wvxf-dwi5.json`, {
        params: {
          BIN: bin
        }
      });

      let violations = response.data;
      if (showActiveOnly) {
        violations = violations.filter(violation => violation.violationstatus === 'Open');
      }
      const sortedViolations = violations.sort((a, b) => {
        const dateA = parseISO(a.currentstatusdate);
        const dateB = parseISO(b.currentstatusdate);
        return dateB - dateA; // Sort by date descending
      });

      setHpdViolations(sortedViolations);
    } catch (error) {
      console.error('Error fetching HPD violations:', error);
    }
  };

  const fetchLandmarkViolations = async (bin) => {
    try {
      const response = await axios.get(`https://data.cityofnewyork.us/resource/wycc-5aqt.json`, {
        params: {
          BIN: bin
        }
      });

      let violations = response.data;
      if (showActiveOnly) {
        violations = violations.filter(violation => violation.rescinded === 'Active');
      }
      const sortedViolations = violations.sort((a, b) => {
        const dateA = parseISO(a.vio_date);
        const dateB = parseISO(b.vio_date);
        return dateB - dateA; // Sort by date descending
      });

      setLandmarkViolations(sortedViolations);
    } catch (error) {
      console.error('Error fetching Landmark violations:', error);
    }
  };

  const fetchFdnyViolations = async (property) => {
    const address = property.features[0].properties.name;
    const borough = property.features[0].properties.borough.toUpperCase();
    setAddress(address);
    setBorough(borough);

    try {
      const response = await axios.get(`https://data.cityofnewyork.us/resource/erm2-nwe9.json`, {
        params: {
          agency: 'FDNY',
          incident_address: address,
          borough: borough,
        }
      });

      let violations = response.data;
      if (showActiveOnly) {
        violations = violations.filter(violation => !(violation.status.includes('Closed')));
      }
      const sortedViolations = violations.sort((a, b) => {
        const dateA = parseISO(a.created_date);
        const dateB = parseISO(b.created_date);
        return dateB - dateA; // Sort by date descending
      });

      setFdnyViolations(sortedViolations);
    } catch (error) {
      console.error('Error fetching FDNY violations:', error);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const apiUrl = 'https://pvsvdfqqv4.execute-api.us-east-1.amazonaws.com/dev/violations/subscribe';

    try {
      const response = await axios.post(apiUrl, {
        email: email,
        bin: bin,
      });

      console.log(response.data);
      alert('Subscription successful!');
    } catch (error) {
      console.error('Error subscribing:', error);
      alert('There was an error subscribing.');
    }
  };

  const handleToggleChange = () => {
    setShowActiveOnly(prevState => !prevState);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    navigate(`/violations?address=${encodeURIComponent(input)}&violations=${tab}`);
  };

  const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}  

  const renderViolationsTable = (violations, type) => (
    <table className='table'>
      <thead>
        <tr>
          <th style={{ border: '1px solid black', padding: '8px' }}>Number</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Description</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Status</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Date</th>
        </tr>
      </thead>
      <tbody>
        {violations.map((violation, index) => {
          let number, description, status, dateToUse, violationUrl;

          switch (type) {
            case 'DOB':
              number = violation.number;
              description = violation.description;
              if (!description || description.length <= 3) {
                if (violation.disposition_comments) {
                  description = violation.disposition_comments;
                } else if (violation.violation_type) {
                  description = violation.violation_type;
                }
              }
              status = violation.violation_category;
              dateToUse = violation.disposition_date || violation.issue_date;
              violationUrl = `https://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=2&allinquirytype=BXS3OCV4&allbin=${bin}&allisn=${violation.isn_dob_bis_viol}`;
              break;
            case 'ECB':
              number = violation.ecb_violation_number;
              description = violation.violation_description;
              status = violation.hearing_status;
              dateToUse = violation.hearing_date || violation.served_date || violation.issue_date;
              violationUrl = `https://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?requestid=2&ecbin=${violation.ecb_violation_number}`;
              break;
            case 'HPD':
              number = violation.violationid;
              description = violation.novdescription;
              status = violation.currentstatus;
              dateToUse = violation.currentstatusdate;
              violationUrl = 'https://hpdonline.nyc.gov/hpdonline/';
              break;
            case 'Landmarks':
              number = violation.lpc;
              description = violation.violation_class;
              status = violation.rescinded;
              dateToUse = violation.vio_date;
              violationUrl = 'https://www.nyc.gov/site/lpc/violations/landmark-violation-search.page';
              break;
            case 'FDNY':
              number = violation.unique_key;
              description = violation.complaint_type
              status = violation.status
              dateToUse = violation.created_date;
              violationUrl = 'https://fires.fdnycloud.org/CitizenAccess/Default.aspx';
              break;
            default:
              break;
          }

          const formattedDate = dateToUse ? format(parseISO(dateToUse), 'yyyy-MM-dd') : 'N/A';          

          return (
            <tr key={index}>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                <a href={violationUrl} target="_blank" rel="noopener noreferrer">
                  {number}
                </a>
              </td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{description}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{status}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{formattedDate}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className='body'>
      <div className='title'>
        <a style={{ textDecoration: 'none' }} href="/violations"><img src="violations_title.png" width={550}/></a>
      </div>
      <form onSubmit={handleSearchSubmit} style={{ textAlign: 'center', marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Enter an address"
          value={input}
          onChange={handleInputChange}
          className='search-box'
        />
        <button type="submit" className='button'>Search</button>
      </form>

      {!property && (
        <div style={{ color: 'red' }}>
          <h2>Address Not Found</h2>
        </div>
      )}

      {showResults && bin && (
        <>
          <h2>{titleCase(address)}, {titleCase(borough)}</h2>
          {/* <img style={{paddingBottom: '24px'}} src="subscribe.png" width={100} /> */}

          <div className="controls-table-container centered-container">
            <div className="buttons-container">
              <button
                onClick={() => handleTabChange('DOB')}
                className={`button ${selectedTab === 'DOB' ? 'selected' : ''}`}
              >
                DOB
              </button>
              <button
                onClick={() => handleTabChange('ECB')}
                className={`button ${selectedTab === 'ECB' ? 'selected' : ''}`}
              >
                ECB
              </button>
              <button
                onClick={() => handleTabChange('HPD')}
                className={`button ${selectedTab === 'HPD' ? 'selected' : ''}`}
              >
                HPD
              </button>
              <button
                onClick={() => handleTabChange('LPC')}
                className={`button ${selectedTab === 'LPC' ? 'selected' : ''}`}
              >
                LPC
              </button>
              <button
                onClick={() => handleTabChange('FDNY')}
                className={`button ${selectedTab === 'FDNY' ? 'selected' : ''}`}
              >
                FDNY
              </button>
            </div>
            <div className='switch-container'>
              ACTIVE
                <label className='switch'>
                  <input
                    type="checkbox"
                    checked={showActiveOnly}
                    onChange={handleToggleChange}
                  />
                  <span className='slider'></span>
                </label>
              </div>
          </div>

          {selectedTab === 'DOB' && (
            <>
              {renderViolationsTable(dobViolations, 'DOB')}
            </>
          )}
          {selectedTab === 'ECB' && (
            <>
              {renderViolationsTable(ecbViolations, 'ECB')}
            </>
          )}
          {selectedTab === 'HPD' && (
            <>
              {renderViolationsTable(hpdViolations, 'HPD')}
            </>
          )}
          {selectedTab === 'LPC' && (
            <>
              {renderViolationsTable(landmarkViolations, 'Landmarks')}
            </>
          )}
          {selectedTab === 'FDNY' && (
            <>
              {renderViolationsTable(fdnyViolations, 'FDNY')}
            </>
          )}
        </>
      )}

      <div className="cue-container">
        <div className="cue-text">
          Contact us to resolve your violations
          <div className="cue-arrow"></div>
        </div>
      </div>


    </div>

);

}

Intercom({
  app_id: 'um2dvafw',
});

export default Violations;
